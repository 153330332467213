<template>
  <v-navigation-drawer
    :value="sidebarVisibility"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
  >

    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Panel de Control
        </v-list-item-title>
        <v-list-item-subtitle>
          Control y Gestión de Recursos
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <template
        v-for="item in items"
      >
        <v-list-item 
          v-if=" !item.childs "   
          :key="item.title"
          :to="{ name: item.route }"        
          link  
          color="primary"       
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if=" item.dividerTop " :key="item.route"></v-divider>

        <v-list-group
          v-if=" item.childs "
          :prepend-icon="item.icon"
          :value="item.submenuCollapsed"
          :key="item.title"
          @click="toggleSubmenuVisibility(item.submenu)"
        >
          <!--  @click="toggleCashFlowSubmenuVisibility" -->
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>   
          <v-list-item 
            v-for=" subitem in item.childs " 
            :key=" subitem.title "
            :to="{ name: subitem.route }"          
            link
          >
            <v-list-item-icon>
              <v-icon>{{ subitem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ subitem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>        
        </v-list-group>      
      </template>
    </v-list>  
  </v-navigation-drawer>  
</template>

<script>
import { mapActions, mapGetters } from 'vuex' 

export default {
  name: "TheSidebar",
  data: () => ({
    items: [
      { 
        title: "Dashboard", 
        icon: "mdi-view-dashboard", 
        route: "dashboard"  
      },
      
    ],
    /* mdi-ferry */
  }),
  mounted () {
    this.items[6].submenuCollapsed = this.cashFlowSubmenuVisibility
    this.items[7].submenuCollapsed = this.referenceManagementSubmenuVisibility
  },
  computed: {
  },
  methods: {
  }
};
</script>
