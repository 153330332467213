<template>
  <v-snackbar
      v-model="snackbar"
      top
      :timeout="snackbarTimeout"
      :color="snackbarColor"
  >
    {{ snackbarText }}
    <template v-slot:action="{ attrs }">
      <v-btn
        v-if=" snackbarColor "
        @click="deleteMessage(); snackbar = false"
        v-bind="attrs"
        class="ml-2"
        text
        small
      >
        Cerrar
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    snackbar: false,
    snackbarText: "",
    snackbarColor: "",
    snackbarTimeout: -1
  }),
  mounted(){
    //console.log(this.globalMessage)
  },
  computed: {
    ...mapGetters("messageHandler", ["globalMessage"])
  },
  methods:{
    ...mapMutations("messageHandler", ["DELETE_MESSAGE"]),
    deleteMessage(){
      this.$store.commit("messageHandler/DELETE_MESSAGE")
    }
  },
  watch:{
    "globalMessage": {
      handler: function (newMessages) {
        if( newMessages.length > 0){
          //let errorTitle = ( newMessages[0].status != 200 ) ? "Error " : ""
          let errorTitle = ''
          let timeout = false
          this.snackbar = true
          this.snackbarText = errorTitle + newMessages[0].status + ": " + newMessages[0].statusText

          //this.snackbarColor = newMessages[0].status != 200 ? "error" : "success"
          switch (newMessages[0].status) {
            case 200: 
              errorTitle = ''
              this.snackbarColor = "success"
              this.snackbarTimeout = 3000
              timeout = true
              break;
            case 204: 
              this.snackbarColor = "warning"
              errorTitle = ''
              this.snackbarTimeout = 3000
              timeout = true
              break;
            case 400: 
              errorTitle = 'Error '
              this.snackbarColor = "error"
              this.snackbarTimeout = 0
              timeout = false
              break;
            default:
              errorTitle = 'Error '
              this.snackbarColor = "error"
              this.snackbarTimeout = 0
              timeout = false
              break;
          }

          //this.snackbarTimeout = newMessages[0].status != 200 || newMessages[0].status != 204 ? 0 : 3000

          /* if( newMessages[0].status == 200 || newMessages[0].status == 200 204 ){
            setTimeout(() => {
              this.$store.commit("messageHandler/DELETE_MESSAGE")
            }, 3000);            
          } */
          if( timeout ){
            setTimeout(() => {
              this.$store.commit("messageHandler/DELETE_MESSAGE")
            }, 3000);            
          }
        }else{
          /* this.snackbar = false
          this.snackbarText = ""
          this.snackbarColor = "error"
          this.snackbarTimeout = 1000 */
        }
      },
      deep: true
    }
  }
}
</script>