<template>
  <div>

    <!-- <the-sidebar/> -->

    <the-header/>

    <v-main>
      <v-container
        fluid
      >
        <v-row class="pa-5">
          <v-col>

            <v-btn
              @click="toTop"
              v-scroll="onScroll"
              v-show="fab"
              color="primary"
              fab
              dark
              fixed
              bottom
              right   
              small       
            >
              <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        
            <router-view/>

          </v-col>
        </v-row>

      </v-container>
    </v-main>

    <global-message/>

    <the-footer/>

  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader";
import TheSidebar from "@/components/TheSidebar";
import TheFooter from "@/components/TheFooter";
import GlobalMessage from "@/components/GlobalMessage";

export default {
  name: "control-panel",
  components: {
    TheHeader,
    TheSidebar,
    TheFooter,
    GlobalMessage
  },
  data: () => ({
    fab: false
  }),
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }    
  }
};
</script>