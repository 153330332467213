<template>
  <v-app-bar 
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    color="primary"
    hide-on-scroll
    app    
    dark
  >

    <!-- <v-app-bar-nav-icon class="ml-1"></v-app-bar-nav-icon> -->
    
    <v-avatar
      class="elevation-2 ml-5 mr-3"
      color="secondary"
      size="40"
    >
      <v-icon color="primary lighten-5" class="elevation-2">mdi-domain</v-icon>
    </v-avatar>

    <v-toolbar-title>
      TCM & Integrative Medicine Institutions Index
    </v-toolbar-title>

    <v-spacer></v-spacer>
        
    <!-- Hola {{ getUserData != null ? getUserData.usr_name : '' }}

    <v-spacer></v-spacer> -->

    <!-- <v-btn
      text
      @click="onLogout"
    >
      <span class="mr-2">Cerrar Sesión</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn> -->

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-list color="primary">
          <v-list-item  
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-avatar>
              <v-avatar 
                class="elevation-5"  
                :color="getUserData.usr_color"
                size="40"
              >
                <h4 class="font-weight-regular white--text">{{ getUserData.usr_initials }}</h4>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="text-end">
              <v-list-item-title><span class="font-weight-light">Hi,</span> {{ getUserData.usr_name }}</v-list-item-title>
              <!-- <v-list-item-subtitle class="text-caption"><span class="font-weight-light">Eres,</span>{{ getUserData.usr_role }}</v-list-item-subtitle> -->
            </v-list-item-content>          
          </v-list-item>
        </v-list>
      </template>
      <v-list two-line dense>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">
              mdi-card-account-details-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Rol</v-list-item-subtitle>
            <v-list-item-title>{{ getUserData.usr_role }}</v-list-item-title>            
          </v-list-item-content>
        </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-email
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-subtitle>Email</v-list-item-subtitle>
          <v-list-item-title>{{ getUserData.usr_email }}</v-list-item-title>          
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">
            mdi-calendar-clock-outline
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-subtitle>Último login</v-list-item-subtitle>
          <v-list-item-title>{{ convertDate(getUserData.usr_last_login_date, 1) }}</v-list-item-title>          
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-center">
            <v-btn
              @click="onLogout"
              color="primary" 
              small             
            >
              <span class="mr-2">Cerrar Sesión</span>
              <v-icon small>mdi-logout-variant</v-icon>
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    </v-menu>

    <template v-slot:extension>
      <v-tabs v-if=" getUserData != null " class="mr-2" align-with-title>
        <template
          v-for="(item) in menu" 
        >
          <template v-if=" item.roles.includes(getUserData.usr_role) ">
            <v-tab
              :to="{ name: item.route }"         
              :key="item.title"
              link  
            >
              {{ item.title }}
            </v-tab>
          </template>
        </template>
        <v-spacer></v-spacer>
        <v-tab
          v-if=" getUserData.usr_role == 'admin' "
          :to="{ name: 'admin-tools' }"         
          key="admin-tools"
          link 
        > 
          Admin Tools
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex' 
import { mixinGlobal } from '@/mixins/mixinGlobal'

export default {
  name: "TheHeader",
  mixins: [ mixinGlobal ],
  data: () => ({
    menu: [
      { 
        title: "Buscador", 
        icon: "mdi-view-dashboard", 
        route: "search",
        roles: ['admin', 'editor', 'suscriber']
      },
      { 
        title: "Instituciones Propuestas", 
        icon: "mdi-view-dashboard", 
        route: "institution-proposals",
        roles: ['admin', 'editor']
      },
      { 
        title: "Instituciones Aprobadas", 
        icon: "mdi-view-dashboard", 
        route: "institution-management",
        roles: ['admin']
      },
      { 
        title: "Gestión usuarios", 
        icon: "mdi-view-dashboard", 
        route: "user-management",
        roles: ['admin']
      },
      { 
        title: "Ayuda", 
        icon: "mdi-view-dashboard", 
        route: "help",
        roles: ['admin', 'editor', 'suscriber']
      },
    ],
    userMenu: false
  }),
  computed: {
    ...mapGetters('auth', ['getUserData']),
  },
  methods: {
    /* ...mapActions(['SET_SIDEBAR_VISIBILITY']),
    toggleSidebarVisiblility(){
      this.$store.commit('SET_SIDEBAR_VISIBILITY',!this.sidebarVisibility);
    }, */
    onLogout(){
      this.$store.dispatch('auth/LOGOUT')
    }
  }
};
</script>
