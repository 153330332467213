export const mixinGlobal = {
	data(){
		return{
		}
	},
  computed:{
  },
  methods: {
    toCurrency: (value) => { 
      value = parseFloat(value).toFixed(2) // always two decimal digits
      value = value.replace(".", ",") // replace decimal point character with ,
      value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €" // use . as a separator
      return value;
      //parseFloat(value).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' }) 
    },
    toDecimal: (value) => {
      return Math.round( value * 1e2 ) / 1e2;
    },
    convertDate: (inputDate, time = false) =>  {
      if( !inputDate ){
        return ""
      }else{
        var date = new Date(inputDate)
        if( !time ){      
          return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        }else{
          return ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2) + ":" + ("0" + date.getSeconds()).slice(-2) + " " + ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear();
        }
      }
    },
    getMysqlDate(){
      let date = new Date() 
      return date = date.getFullYear() + '-' +
                ('00' + (date.getMonth()+1)).slice(-2) + '-' +
                ('00' + date.getDate()).slice(-2) + ' ' + 
                ('00' + date.getHours()).slice(-2) + ':' + 
                ('00' + date.getMinutes()).slice(-2) + ':' + 
                ('00' + date.getSeconds()).slice(-2);
    },
    mysqlDateTimeToJsDate(dateTime){
      let dateTimeParts = dateTime.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
      dateTimeParts[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one
      return new Date(...dateTimeParts); // our Date object
    }
  },
}